import { Component } from '@angular/core';

import {AlertController, Events, MenuController, ModalController} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {NavigationStart, Router} from '@angular/router';
import {StorageService} from './providers/storage.service';
import {PlatformService} from './providers/platform.service';
import {UsuarioApiService} from './providers/usuario-api.service';
import {Usuario} from './models/usuario.model';
import {ConnexionApiService} from './providers/connexion-api.service';
import {NavigationEvent} from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import {filter} from 'rxjs/operators';
import {AndroidFullScreen} from '@ionic-native/android-full-screen/ngx';
import {UdegUserService} from './providers/udeg.user.service';
import { environment } from '../environments/environment.prod';
import { AnalyticsService } from './providers/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  webVersion = true;

  usuario: Usuario;

  currentUrl: string = '';

  splash = true;

  public appPages = [
    {
      title: 'Inicio',
      url: '/home',
      auth: true
    },
    {
      title: 'Promociones',
      url: '/promociones',
      auth: true
    },
    {
      title: 'Categorías',
      url: '/categoria-lista',
      auth: true
    },
    {
      title: 'Recintos',
      url: '/recinto-lista',
      auth: true
    },
    {
      title: 'Buscar',
      url: '/busqueda',
      icon: 'search',
      auth: true
    },
    {
      title: 'Sitios de interés',
      url: '/sitios-interes',
      onlyDevice: true,
      auth: false
    },
    {
      title: 'Notificaciones',
      url: '/notificacion',
      icon: 'notifications-outline',
      notify: false
    }
  ];

  // Listas de url permitidas a mostrar directamente
  whiteList = [ '/promo', '/info/privacidad', '/login', '/registro', '/info/tyc', '/info/ayuda' ];
  urlBackBlackList = [ '/login', '/registro', '/intereses', '/google-auth/login', '/google-auth/register',
    '/facebook-auth/login', '/facebook-auth/register'];

  constructor( public platform: PlatformService, private splashScreen: SplashScreen,  private statusBar: StatusBar,
               private router: Router, public storage: StorageService, private menu: MenuController, private events: Events,
               private usuarioService: UsuarioApiService, private androidFullScreen: AndroidFullScreen,
               private udegUserService: UdegUserService, private alertController: AlertController) {
    this.initializeApp();
  }

  initializeApp() {

    this.platform.ready().then( async () => {
      this.splashScreen.hide();
      this.statusBar.styleDefault();
      this.storage.getToken();
      this.storage.getRefreshToken(); // si el refresh token se encuentra, el usuario decidió recordar login
      this.usuario = await this.storage.getUsuario();

      if (this.platform.is('android')) {

        await this.androidFullScreen.immersiveMode()
            .then(() => console.log('Immersive Mode activated!'))
            .catch(() => console.log('Immersive Mode NO activated!'));
      }

      this.events.subscribe('perfil:updated', (usuario: Usuario) => {

        this.usuario = usuario;
        this.setNuevaNotificacion(usuario && (usuario.nuevasNotificaciones > 0));
      });

      this.webVersion = environment.buildWeb ? this.platform.isDesktop() : false; 

      if (this.storage._usuario && this.storage._refreshToken) {

        this.storage._isAuth = true;
        if(this.usuario.id == 0) {
          return
        }
        this.usuarioService.recuperarPerfil(this.usuario.id);
      } else {

        // if (this.platform.isWebBuild()) {

        //   if (this.whiteList.indexOf(this.router.url) < 0) {
        //     await this.router.navigate([ '/promo' ]);
        //   }

        // } else {

          // await this.router.navigate([ '/login' ]);
          this.storage.irLogin();
        // }
      }

      
      this.splash = false;
      this.storage._webSplashLoading = false;

      this.events.subscribe('notify:updated', (notify) => {

        this.setNuevaNotificacion(notify);
      });

      this.initializeRouter();
    });
  }

  setNuevaNotificacion(value) {

    const page: any = this.appPages.find(p => p.title === 'Notificaciones');
    if (page) {
      page.notify = value;
    }
  }

  fotoPerfil() {

    if (!this.usuario || ( !this.usuario.foto && !this.usuario.fotoUrl) ) {

      return;
    }

    return this.usuario.foto ? `${ConnexionApiService.host}${this.usuario.foto}` : this.usuario.fotoUrl;
  }

  async cerrarSession() {

    // await this.usuarioService.logoutAndunsubscribeCloudMessaging();
    this.udegUserService.resetData();
    // location.reload();
  }

  initializeRouter() {

    // @ts-ignore
    this.router.events.pipe( filter(
        ( event: NavigationEvent ) => {

          // @ts-ignore
          return (event instanceof NavigationStart);
        }
    )).subscribe(
          async (event:any) => {

          // console.group( "NavigationStart Event" );

          // // Every navigation sequence is given a unique ID. Even "popstate"
          // // navigations are really just "roll forward" navigations that get
          // // a new, unique ID.
          // console.log( "navigation id:", event.id );
          // console.log( "route:", event.url );

          // // The "navigationTrigger" will be one of:
          // // --
          // // - imperative (ie, user clicked a link).
          // // - popstate (ie, browser controlled change such as Back button).
          // // - hashchange
          // // --
          // // NOTE: I am not sure what triggers the "hashchange" type.
          // console.log( "trigger:", event.navigationTrigger );

          // // This "restoredState" property is defined when the navigation
          // // event is triggered by a "popstate" event (ex, back / forward
          // // buttons). It will contain the ID of the earlier navigation event
          // // to which the browser is returning.
          // // --
          // // CAUTION: This ID may not be part of the current page rendering.
          // // This value is pulled out of the browser; and, may exist across
          // // page refreshes.
          if ( event.restoredState ) {

            console.warn(
                "restoring navigation id:",
                event.restoredState.navigationId
            );

          }

          // console.log(`From ${this.currentUrl} to ${event.url}`);

          // TODO: Usar CanActivate
          if (event.navigationTrigger === 'popstate' && this.urlBackBlackList.indexOf(event.url) >= 0
              && this.storage._isAuth) {

              // console.log('Avoid to update!');
              await this.router.navigate([ '/home' ]);
          } else if (this.currentUrl === '/cuenta-telefono' &&
               (event.url.indexOf('verificar-cuenta') < 0 ) && ( event.url !== '/info/tyc' ) ) {

            await this.router.navigate([ '/cuenta-telefono' ]);
          } else {

            // console.log('Update');
            this.currentUrl = event.url;
          }

          console.groupEnd();
        }
    );
  }

  async closeMenu() {

    await this.menu.close();
  }

  async cuponesUdeG() {
    if(this.storage._usuario.username != 'invitado') {
      if (!this.usuario.codigo_ude_g) {
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Inicia Sesión UdeG',
          inputs: [
            {
              name: 'codigo',
              type: 'text',
              placeholder: 'Código UdeG',
            },
            // {
            //   name: 'nip',
            //   type: 'text',
            //   placeholder: 'NIP',
            // }
          ],
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              handler: () => { }
            },
            {
              text: 'Enviar',
              handler: (data) => {
                console.log('Confirm Ok', data);
                this.usuarioService.statusUdegUser(data.codigo).subscribe(async (dataReques) => {
                  if(dataReques.respuesta == false) {
                    const alertMensaje = await this.alertController.create({
                      cssClass: 'my-custom-class',
                      header: 'Inicia Sesión UdeG',
                      message: 'Tus datos no coinciden, Intenta de Nuevo',
                      buttons:['Ok']});
                      await alertMensaje.present();
                  }
                  else {
                    localStorage.setItem('userUdeGDatos', JSON.stringify({ codigo:data.codigo, nip:data.nip }));
                    let usuarioId = await localStorage.getItem('usuarioId');
                    await this.usuarioService.actualizarPerfil(usuarioId, {
                      codigo_ude_g: data.codigo,
                      nip: data.nip,
                    }).toPromise();
                    const alertMensaje = await this.alertController.create({
                      cssClass: 'my-custom-class',
                      header: 'Inicia Sesión UdeG',
                      message:'Datos verificados',
                      buttons:['Ok']});
                      await alertMensaje.present();
                      await this.router.navigate([ "/cupones" ]);
                  }
                }, async (err) => {
                  console.log(err);
                  const alertMensaje = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Inicia Sesión UdeG',
                    message: err.message || 'Tus datos no coinciden' + ', Intenta de Nuevo',
                    buttons:['Ok']});
                    await alertMensaje.present();
                });
              },
            },
          ],
        });
    
        await alert.present();
      } else {

        await this.router.navigate([ "/cupones" ]);
      }
    } else {
      const alertMensaje = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: '¡Alerta!',
        message: 'Para consultar este contenido necesitas registrarte',
        buttons:[
          { 
            text : 'Continuar como invitado',
            role: 'cancel',
            handler: () => {  }
          },
          { 
            text : 'Crear usuario',
            handler: async () => {
              this.udegUserService.resetData();
              await this.udegUserService.mostrarModal({
                target: '/registro',
                declineTarget: '/registro',
                successLabelBtn: 'Continuar con el registro'
              });
            }
          }
        ]});
      await alertMensaje.present();
    }
  }

  async MenuAbriendo() {
    console.log(this.usuario);
    if(this.usuario == undefined) {
      this.usuario = await this.storage.getUsuario();
    }
  }

  async irPerfil() {
    if(this.storage._usuario.username != 'invitado') {
      await this.router.navigate([ "/perfil" ]);
    } else {
      const alertMensaje = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Inicia Sesión UdeG',
        message: 'Estás en modo invitado, para consultar este contenido necesitas registrarte',
        buttons:['Ok']});
      await alertMensaje.present();
    }
  }

}
