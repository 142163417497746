import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss'],
})
export class DaterangePickerComponent implements OnInit {

  hoveredDate: NgbDate;

  fromDate: NgbDate = null;

  toDate: NgbDate = null;

  inPopover = false;

  @Input()
  vertical = true;

  @Input()
  showUnselect = false;

  @Output()
  selectedRange = new EventEmitter();

  constructor(private popoverController: PopoverController, private calendar: NgbCalendar) {

    this.fromDate = calendar.getToday();
    this.toDate = null;
  }

  ngOnInit() {}

  async limpiar() {
    this.fromDate = null;
    this.toDate = null;
    this.onRangeSelected();
  }

  async onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.onRangeSelected();
  }

  seleccionarDia() {
    this.onRangeSelected(true);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  async onRangeSelected(oneDay = false) {
    if(this.toDate == null && oneDay) {
      this.toDate = this.fromDate;
    }
    const data = {
      from: this.fromDate ? new Date(this.fromDate.year, this.fromDate.month,this.fromDate.day) : null,
      to: this.toDate ? new Date(this.toDate.year, this.toDate.month,this.toDate.day) : null
    };

    if (this.inPopover && ( (!this.fromDate && !this.toDate) || (this.fromDate && this.toDate) )) {

      await this.popoverController.dismiss(data);
      this.sendData(data);
    } else if (!this.inPopover) {

      this.sendData(data);
    }

  }

  sendData(data: any) {
    // console.log('data');
    // console.log(data);
    if (this.selectedRange) {

      this.selectedRange.emit(data);
    }
  }

  handleClick(event: MouseEvent, date: NgbDate) {
    event.preventDefault();
    this.onDateSelection(date);
    // console.log('click', date);
  }
  
  handleTouch(event: TouchEvent, date: NgbDate) {
    event.preventDefault();
    this.onDateSelection(date);
    // console.log('touch', date);
  }

}
