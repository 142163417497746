import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsuarioApiService} from '../providers/usuario-api.service';
import {StorageService} from '../providers/storage.service';
import {PlatformService} from '../providers/platform.service';
import {AlertController, MenuController, ModalController, Platform} from '@ionic/angular';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UdegUserService} from '../providers/udeg.user.service';
import { AuthFirebaseService } from '../providers/auth-firebase.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  loginForm: FormGroup;

  submitted = false;

  recordar = false;

  webVersion = false;

  passwordRecovery = false;

  showAlert = false;
  showPassword = false;
  constructor(private formBuilder: FormBuilder, private usuarioService: UsuarioApiService,
              public storage: StorageService, private platform: PlatformService,
              private modalService: NgbModal, private alertController: AlertController,
              private authFirebaseService: AuthFirebaseService, public platformCheck: Platform,
              private udegUserService: UdegUserService, private menu: MenuController) {

    this.loginForm = this.formBuilder.group({
      username: [ '', [ Validators.required ] ],
      password: [ '', [ Validators.required ] ]
    });
  }

  onSubmit() {

    this.submitted = true;
    this.passwordRecovery = false;

    if (this.loginForm.invalid) {
      return;
    }

    this.login(this.loginForm.value);
  }

  modoInvitado() {
    this.login({password :  "invitado", username :  "invitado"});
  }

  login(credentials) {
    this.usuarioService.login(credentials, this.recordar, 1, 0);
    this.storage.setNormalLogin('Si');
  }

  async recuperarPassword(modalContent) {

    if (!this.loginForm.controls.username.valid) {

      this.passwordRecovery = true;
      return;
    }

    this.passwordRecovery = false;
    const alert = await this.alertController.create({
      message: `¿Realmente quieres reiniciar la contraseña de "${this.loginForm.get('username').value}"?`,
      buttons: [
          {
              text: 'OK',
              handler: () => {

                this.usuarioService.solicitarRecuperarPassword(this.loginForm.get('username').value).subscribe(() => {
                  if (this.webVersion) {
                    this.showAlert = true;
                  } else {
                    this.modalService.open(modalContent, { centered: true, windowClass: 'bluegreen-modal' });
                  }
                }, (error) => {
                  this.showErrorMessage(error)
                });
              }
          },
          'Cancel'
      ]
    });

    await alert.present();
  }

  async showErrorMessage(error) {

    const alert = await this.alertController.create({
      message: `No se puede recuperar la contraseña. ${error ? error.message: ''}`,
      buttons: [ 'OK' ]
    });

    await alert.present();
  }

  cambioUsername() {

    const valor: string = this.loginForm.get('username').value;
    if (valor && valor.indexOf(' ') >= 0) {
      this.loginForm.get('username').setValue(valor.replace(' ', ''));
    }
  }

  ngOnInit(): void {
    this.showAlert = false;
    this.webVersion = this.platform.isDesktop();
  }

  async ionViewWillEnter() {
    this.menu.enable(false);
    this.ngOnInit();
    let user = await this.storage.getUsuario();
    this.showPassword = false;
    if (user) {
      this.loginForm.reset({
        username: user.username ? (user.username == 'invitado' ? '' : user.username) : user.correo
      });
      const rftoken = await this.storage.getRefreshToken();
      if(user.cuenta_verificada == 1 && rftoken){
        const usuarioId = await this.storage.getUsuarioId();
        await this.usuarioService.recuperarPerfil(usuarioId, 1, 0);
      }
    }
  }

  hideAlert() {
    this.showAlert = false;
  }

  async udegUser() {

    await this.udegUserService.mostrarModal({
      target: '/registro',
      declineTarget: '/registro',
      successLabelBtn: 'Continuar con el registro'
    });
  }

  loginApple(){
    this.authFirebaseService.openAppleSignIn('registro');
  }

  showPass() {
    this.showPassword = !this.showPassword;
  }

}
