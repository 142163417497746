import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from './providers/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'crear-cuenta',
    loadChildren: () => import('./crear-cuenta/crear-cuenta.module').then( m => m.CrearCuentaPageModule)
  },
  {
    path: 'verificar-cuenta/:numero',
    loadChildren: () => import('./verificar-cuenta/verificar-cuenta.module').then( m => m.VerificarCuentaPageModule)
  },
  {
    path: 'cuenta-telefono',
    loadChildren: () => import('./cuenta-telefono/cuenta-telefono.module').then( m => m.CuentaTelefonoPageModule)
  },
  {
    path: 'cuenta-telefono/:resend',
    loadChildren: () => import('./cuenta-telefono/cuenta-telefono.module').then( m => m.CuentaTelefonoPageModule)
  },
  {
    path: 'intereses',
    loadChildren: () => import('./intereses/intereses.module').then( m => m.InteresesPageModule)
  },
  {
    path: 'facebook-auth/:type',
    loadChildren: () => import('./facebook-auth/facebook-auth.module').then( m => m.FacebookAuthPageModule)
  },
  {
    path: 'google-auth/:type',
    loadChildren: () => import('./google-auth/google-auth.module').then( m => m.GoogleAuthPageModule)
  },
  {
    path: 'promociones',
    loadChildren: () => import('./promociones/promociones.module').then( m => m.PromocionesPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'categoria-lista',
    loadChildren: () => import('./categoria-lista/categoria-lista.module').then( m => m.CategoriaListaPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'categoria/:id',
    loadChildren: () => import('./categoria/categoria.module').then( m => m.CategoriaPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'perfil',
    loadChildren: () => import('./perfil/perfil.module').then( m => m.PerfilPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'recinto-lista',
    loadChildren: () => import('./recinto-lista/recinto-lista.module').then( m => m.RecintoListaPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'recinto/:id',
    loadChildren: () => import('./recinto/recinto.module').then( m => m.RecintoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'evento/:id',
    loadChildren: () => import('./evento/evento.module').then( m => m.EventoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'busqueda',
    loadChildren: () => import('./busqueda/busqueda.module').then( m => m.BusquedaPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'busqueda-resultado',
    loadChildren: () => import('./busqueda-resultado/busqueda-resultado.module').then( m => m.BusquedaResultadoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'sitios-interes',
    loadChildren: () => import('./sitios-interes/sitios-interes.module').then( m => m.SitiosInteresPageModule)
  },
  {
    path: 'promo',
    loadChildren: () => import('./promo/promo.module').then( m => m.PromoPageModule)
  },
  {
    path: 'notificacion',
    loadChildren: () => import('./notificacion/notificacion.module').then( m => m.NotificacionPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'info/:slug',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'cupones',
    loadChildren: () => import('./cupones/cupones.module').then( m => m.CuponesPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
