import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Evento} from '../models/evento.model';
import {StorageService} from '../providers/storage.service';
import {EventoApiService} from '../providers/evento-api.service';
import {Filtro} from '../models/filtro.model';
import {Categoria} from '../models/categoria.model';
import {Recinto} from '../models/recinto.model';
import {Genero} from '../models/genero.model';
import {ActivatedRoute} from '@angular/router';
import {PlatformService} from '../providers/platform.service';

@Component({
  selector: 'app-busqueda-resultado',
  templateUrl: './busqueda-resultado.page.html',
  styleUrls: ['./busqueda-resultado.page.scss'],
})
export class BusquedaResultadoPage implements OnInit {

  webVersion = false;

  eventos: Evento[] = [];
  eventosfuturos: Evento[] = [];

  filtros: Filtro = new Filtro();

  categorias: Categoria[];

  recintos: Recinto[];

  generos: Genero[];

  loaded = false;

  orderKey = null;

  ordenamiento = [
    { nombre: 'Mayor a menor promoción', valor: { orderBy: 'promocion', orderType: 'desc' } },
    { nombre: 'Fecha lejana', valor: { orderBy: 'fecha', orderType: 'desc' } },
    { nombre: 'Fecha cercana', valor: { orderBy: 'fecha', orderType: 'asc' } },
    { nombre: 'De menor a mayor precio', valor: { orderBy: 'precio', orderType: 'desc' } },
    { nombre: 'De mayor a menor precio', valor: { orderBy: 'precio', orderType: 'asc' } }
  ];

  mostrarOrdenar = false;
  enVista = false;
  constructor(private location: Location, private storage: StorageService, private platform: PlatformService,
              private eventoService: EventoApiService, private routeActivated: ActivatedRoute) { }

  async back () {
    this.enVista = false;
    await this.location.back();
  }

  ngOnInit() {
    this.webVersion = this.platform.isDesktop();
    this.orderKey = this.ordenamiento[2]; // ordenamiento más cercano
  }

  ionViewWillEnter() {
    this.enVista = true;
    this.initialize();
  }

  async initialize() {

    this.filtros = new Filtro(await this.storage.getMetadata());

    console.group('BusquedaPage@onBuscarEventos');
    console.log(this.filtros);
    console.groupEnd();
    this.actualizarBusqueda();

    this.routeActivated.queryParams.subscribe((params: any) => {

      if (params.texto) {
        this.filtros.texto = params.texto;
        this.actualizarBusqueda();
      }
    })
    this.eventosfuturosBusqueda();
  }

  obtenerEntidades() {

    this.categorias = this.storage._categorias.filter((c: Categoria)  => this.filtros.categoria.indexOf(c.id) >= 0);
    this.recintos = this.storage._recintos.filter((r: Recinto)  => this.filtros.recinto.indexOf(r.id) >= 0);

    let generos = [];
    this.categorias.forEach((c: Categoria) => generos = [ ...generos, ... c.generos]);
    this.generos = generos.filter((g: Genero)  => this.filtros.genero.indexOf(g.id) >= 0);

  }

  eliminarEtiqueta(tipo, valor = null) {

    let defaultFiltro = new Filtro();

    if (!valor) {

      this.filtros[tipo] = defaultFiltro[tipo];
    } else {

      const index = this.filtros[tipo].indexOf(valor);
      if (index >= 0) {

        this.filtros[tipo].splice(index, 1);
      }
    }

    this.actualizarBusqueda();
  }

  limpiarEtiquetas() {

    this.filtros = new Filtro();
    this.generos = [];
    this.categorias = [];
    this.recintos = [];
    this.actualizarBusqueda();
  }

  async actualizarBusqueda() {

    this.loaded = false;

    if (!this.filtros) {

      this.filtros = new Filtro();
    }

    this.storage._metadata = this.filtros;
    this.obtenerEntidades();

    const data: any = {
      filtros: this.filtros
    };

    if (this.orderKey && this.orderKey.valor) {

      data.orderBy = this.orderKey.valor.orderBy;
      data.orderType = this.orderKey.valor.orderType;
    }
    let idUser = await this.storage.getUsuarioId();
    const favoritos:any = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
    this.eventoService.filtrarTodoEventos(data, favoritos).subscribe((eventos: Evento[]) => {
      let evnt = eventos;
      evnt.forEach(element => {
        element.liked = this.buscarEventoPorId(favoritos.intereses, element.id);
      });
      let respFiltrado =  this.filtrarYOrdenar(data,evnt);
      let existePromociones = false;
      if(data.orderBy == 'promocion'){
        respFiltrado.forEach(element => {
          if(element.textoPromocion != null) {
            existePromociones = true;
          }
        });
      }
      this.mostrarOrdenar = false;
      this.eventos = (data.orderBy == 'promocion') ?  (existePromociones ? respFiltrado : []) : respFiltrado;
    }, () => {}, () => this.loaded = true);
  }

  buscarEventoPorId(listaEventos, id) {
    return listaEventos.some(evento => evento.evento_id === id);
  }

  isFiltroVacio() {

    return !this.filtros || (!this.filtros.texto && !this.filtros.categoria.length && !this.filtros.genero.length &&
        !this.filtros.recinto.length && !this.filtros.fecha.from &&  !this.filtros.fecha.to &&
        !this.filtros.precio.min && !this.filtros.precio.max);
  }

  ordenarEventos() {
    if (!this.webVersion) {

      this.mostrarOrdenar = false;
    }
    if(this.enVista) {
      this.actualizarBusqueda();
    }
  }

  filtrarYOrdenar(filtro, data) {
    const { orderBy, orderType } = filtro;
    if (!orderBy || !orderType) {
      return data;
    }
    return data.sort((a, b) => {
      let valorA, valorB;
      if (orderBy === 'promocion') {
        valorA = a.texto_promocional === null ? (orderType === 'asc' ? Infinity : -Infinity) : parseInt(a.texto_promocional, 10);
        valorB = b.texto_promocional === null ? (orderType === 'asc' ? Infinity : -Infinity) : parseInt(b.texto_promocional, 10);
      } else if (orderBy === 'fecha') {
        valorA = new Date(a.fecha_inicio);
        valorB = new Date(b.fecha_inicio);
      } else if (orderBy === 'precio') {
        if(orderType == 'desc') {
          valorA = parseFloat(b.precio_bajo);
          valorB = parseFloat(a.precio_bajo);
        } else {
          valorA = parseFloat(b.precio_alto);
          valorB = parseFloat(a.precio_alto);
        }
      }
      if (orderType === 'asc') {
        return valorA - valorB;
      } else {
        return valorB - valorA;
      }
    });
  }

  async onBuscarEventos(data) {
    let filtertemp = data;
    filtertemp.categoria = filtertemp.categoria.filter(item => item !== undefined);
    this.filtros = filtertemp;
    this.actualizarBusqueda();
  }


  async eventosfuturosBusqueda() {
    let idUser = await this.storage.getUsuarioId();
    let filtro:any = this.filtros;
    filtro.limite = 20
    
    const favoritos:any = await this.eventoService.obtenerEventoFavoritos(idUser).toPromise();
    this.eventoService.filtrarFuturosEventos(filtro).subscribe((eventos: Evento[]) => {
      let evnt = eventos;
      evnt.forEach(element => {
        element.liked = this.buscarEventoPorId(favoritos.intereses, element.id);
      });
      this.eventosfuturos = evnt;
    });
  }

}
